/*  background-color: rgb(246, 144, 35); */
/* the background color for the navbar should be modified, logo should be refactored to be compatible
with the rest of elements in the navbar */

.header {
    /* min-height: 520px; */
    /* height: 100vh; */
    /* width: 100vh; */
    /* position: relative; */
    /* overflow: hidden; */
    /* background-image: url("./background3.jpg"); */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    /* background-attachment: fixed; */
}

.header {
    /* position: relative; */
    /* background-repeat: no-repeat; */
    /* height: 100vh; Full height */
    /* background-image: url('./background3.jpg'); Replace with your image URL */
    /* background-size: cover; Cover the whole header */
    /* background-position: center; Center the background */
}

.header .header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
}

.mobile-header-number {
    padding: 13px;
    color: rgb(246, 144, 35);
    text-decoration: none;
    cursor: pointer;
    direction: ltr;
    font-size: 25px;
    font-weight: 700;
    width: 220px;
    background-color: #f5bb00;
    margin-top: 2%;
    border-radius: 25px;
}

.header-txt {
    text-wrap: nowrap
}

.header-p {
    color: white;
    font-weight: 700;
    background-color: cadetblue;
    text-wrap: nowrap;
    font-size: 2.5vw;
    /* margin-top: 9%; */

}

.header-p2 {
    font-size: 20px;
    font-weight: 700;
    border: 3px solid cadetblue;
    color: white;
    text-wrap: wrap;
    /* font-size: 1.5vw; */
    width: 35%;
}

.call-header-btn {
    color: black;
    background-color: #f5bb00;
    border: none;
}

.offCanvas-btn {
    width: 40px;
    height: 30px;
    background-color: #EBE3D5;
    border: none;

}


.header-img {
    position: absolute;
    background-color: red;
    display: block;
    width: 100%;
}

.header .nav {
    background-color: #EBE3D5;
    padding: 25px 20px 34px 20px;
    /* position: fixed; */
    width: 100%;
    /* z-index: 2; */
    height: 50px;
    min-height: 40px;
    top: 0;
}

.header-nav {
    width: 625px;

}

.nav-item a {
    color: black;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    text-wrap: nowrap;
}

.nav-item a:hover {
    color: #198cd8;

}

.logo-a {
    display: inline-block;
    height: 100px;
}

.header-logo {
    width: 52px;
}

.header-brand {
    padding-top: 0px;
}


























.sub-header {
    width: 100%;
    height: 100vh;
    background-image: url("./background-3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.header-txt1 {
    position: relative;
}

.sub-header .btn,
.sub-header .newP,
.sub-header .newP2 {
    width: 30%;
}










.header {
    background-image: url("./background-3.png");
    background-size: cover;
    background-position: center;
    height: 160vh;
}

.header .h1 {
    position: fixed;
}

@media (max-width: 370px) {
    .header {
        height: 45vh !important;
    }

    .header .nav {
        height: 20px !important;
    }

    .header-p {
        font-size: 20px !important;
        margin-top: 8%!important;
    }

    .header-p2 {
        font-size: 20px !important;
        width: 60% !important;
    }

    .mobile-header-number {
        padding: 9px !important;
        width: 182px !important;
    }
}

@media (max-width: 900px) {
    .header {
        height: 60vh !important;
    }

    .header .nav {
        height: 20px !important;
    }

    .header-p {
        font-size: 20px !important;
        margin-top: 8% !important;
    }

    .header-p2 {
        font-size: 20px !important;
        width: 70% !important;
    }

    .mobile-header-number {
        padding: 7px !important;
        width: 182px !important;
    }
}












.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

h1 {
    margin: 0;
}

p {
    margin: 10px 0 0;
}






