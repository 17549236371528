.clients {
    padding-bottom: 100px;
}

.clients-heading {
    color: rgb(37, 37, 37);
    direction: rtl;
    font-size: 34px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    line-height: 42px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.line {
    width: 8%;
    height: 3px;
    background-color: rgb(246, 144, 35);
    margin-left: 46%;
}

.clients-bio {
    color: rgb(37, 37, 37);
    direction: rtl;
    font-size: 22px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    line-height: 22px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.clients-carousel {
    height: 500px;
    position: relative;
    /* width: 50%; */
    /* margin-left: 27%; */
}

.clients-carousel .carousel-inner {
    width: 100%;
    height: 100%;
}

.carousel-indicators {
    display: none;
}

.client-img {
    /* margin-left: auto; */
    /* display: block; */
    /* width: 100%; */
    /* position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; */

}