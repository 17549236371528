.comments {
    margin-bottom: 100px;
    background-color: rgb(235, 235, 235);
    padding-top: 90px;
}

.comments-heading {
    color: rgb(37, 37, 37);
    direction: rtl;
    font-size: 34px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    line-height: 42px;
    margin-bottom: 20px;
}

.line {
    width: 8%;
    height: 3px;
    background-color: rgb(246, 144, 35);
    margin-left: 46%;
}

.sub-heading {
    color: rgb(37, 37, 37);
    direction: rtl;
    font-size: 22px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    line-height: 22px;
    margin-bottom: 60px;
    margin-top: 20px;
}

.comments-car {
    height: 220px;
}

.comments-car .slide {
    margin-left: 0px;
}

.carousel-inner {
    width: 100%;
    height: 100%;
}

.comments-car .carousel-item {
    
}

.carousel-indicators {
    display: none;
}

#comment {
    color: rgb(37, 37, 37);
    direction: rtl;
    font-size: 17.5px;
    line-height: 21px;
    margin-bottom: 24px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
}

/* .comment-line {
    width: 8%;
    height: 3px;
    background-color: rgb(246, 144, 35);
    margin-left: 61%;
    margin-bottom: 15px;
} */

#name {
    font-size: 14px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    /* direction: rtl; */
    line-height: 20px;
}

