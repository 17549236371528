/*  background-color: rgb(246, 144, 35);    or #f69023   */
.previous-work {
    margin-top: 40px;
    padding-bottom: 100px;
}

.previous-header {
    /* font-family: 'Cairo', Arial, sans-serif; */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.line {
    width: 7%;
    height: 3px;
    background-color: rgb(246, 144, 35);
    margin-left: 47%;
}

.previous-description {
    padding: 20px 0px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
}

.previous-details {
    padding: 0px 15%;
}

.icon {
    font-size: 75px;
}

.number {
    margin-top: 20px;
    color: rgb(246, 144, 35);
    direction: ltr;
    font-size: 50px;
    font-weight: 700;
}

.number1 {
    color: red;
    margin-top: 20px;
    direction: ltr;
    font-size: 50px;
    font-weight: 700;
}

.achievement-description {
    color: rgb(246, 144, 35);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px; 
}

.achievement-description1 {
    color: red;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px; 
}