.footer {}

.sub-footer {
    padding: 0px 45px 75px 45px;
}

.copyrights {
    direction: rtl;
}

.copyrights .name {
    color: rgb(37, 37, 37);
    direction: rtl;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    font-size: 17.5px;
    font-weight: 500;
    line-height: 29.7px;
}

.rights {
    color: rgb(37, 37, 37);
    direction: rtl;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    font-size: 14px;
    font-weight: 500;
    line-height: 19.8px;
}

.footer-logo-img {
    height: 70px;
}

.communicate {
    height: 60px;
    background-color: beige;
}

.call-btn {
    border: none;
    padding-top: 15px;
    background-color: beige;

}

.call-btn .whatsapp-link {
    display: block;
    width: 30px;
    height: 40px;
}

.whatsapp-img {
    margin-top: 5px;
}

.fa-brands {
    font-size: 30px;
}

.MyNav {
    width: 100%;
    border: none;
}

.Footer-Nav {
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: beige;
    margin: 0px;
}